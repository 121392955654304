import React, { useContext, useState } from 'react'
import { Modal, notification, Button } from 'antd'
import { AblyContext } from 'App/Providers'

const AlertModal = ({ isOpen, showAlertModal, appointment }) => {
  const [isBlocked, setIsBlocked] = useState(false)
  const { realtime } = useContext(AblyContext)

  return (
    <Modal
      title="Mensaje de Retraso"
      visible={isOpen}
      onCancel={showAlertModal}
      footer={[
        <Button key="back" onClick={showAlertModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isBlocked}
          onClick={() => {
            notification.info({
              message: 'Notificación enviada',
              description: 'Se ha notificado al paciente correctamente',
            })
            const channel = realtime.channels.get(appointment.patient._id)
            channel.publish(
              'latedoctor',
              `Le informamos que su Dr. ${appointment.professional.firstname} ${appointment.professional.lastname} presenta una demora, espere unos minutos mientras se conecta.`,
            )
            setIsBlocked(true)
            setTimeout(() => {
              setIsBlocked(false)
            }, 5000)
            showAlertModal()
          }}>
          Enviar
        </Button>,
      ]}>
      {appointment && (
        <>
          <h4>
            Haga click en el botón <b>Enviar</b> para notificar al paciente que
            el doctor presenta una demora.
          </h4>
          <p>
            &quot;Estimado/a {appointment.patient.firstname}{' '}
            {appointment.patient.lastname}:
          </p>
          <p>
            Le informamos que su Dr. {appointment.professional.firstname}{' '}
            {appointment.professional.lastname} presenta una demora, espere unos
            minutos mientras se conecta.&quot;
          </p>
        </>
      )}
    </Modal>
  )
}

export default AlertModal
