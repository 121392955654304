import React from 'react'
import styles from './index.module.css'
import { Checkbox } from 'antd'

const InvoicesListNamesHeader = ({
  indeterminate,
  onCheckAllChange,
  checkAll,
  invoices
}) => {
  
  return (
    <div className={styles.appointmentListHeaderContainer}>
      <div className={styles.headerListHourContainer}>
        <span>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={invoices?.length<=0 || invoices===undefined}
          />
        </span>
      </div>
      <div className={styles.headerListStateAndDoctorContainer}>
        <span className={styles.headerListState}>Nombre Paciente</span>
        <span className={styles.headerListState}>Rut</span>
      </div>
      <div className={styles.headerListPatientAndSpeciallityContainer}>
        <span className={styles.headerListState}>Fecha Creación</span>
        <span className={styles.headerListState}>Fecha Envío</span>
        <span className={styles.headerListState}>Profesional</span>
      </div>

      <div className={styles.headerListActionContainer}>
        <span>Estado</span>
      </div>
      <div className={styles.headerListActionContainer}>
      </div>
    </div>
  )
}

export default InvoicesListNamesHeader
