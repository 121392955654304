import React from 'react'

import { Button, Tooltip } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const ResendEmail = ({
  invoice,
  handleFunction
}) => {
  return (
    <Tooltip title='Reenviar Email' color={'#2D3F8C'}>
      <Button
        style={{ background: '#2D3F8C', borderColor: '#2D3F8C' }}
        shape='square'
        icon={<MailOutlined />}
        type="primary"
        onClick={() => handleFunction(invoice)}
      />
    </Tooltip>
  )
}

export default ResendEmail
