// eslint-disable-next-line
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

const propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
}

const defaultProps = {}

const Status = ({ color, Text, connectionStatus }) => {
  return (
    <Text style={{ width: 'auto' }} className={styles.statusIndicator}>
      <div className={styles.container}>
        <div
          className={styles.outerDot}
          style={{
            backgroundColor: color,
            filter: 'brightness(1.5)',
          }}></div>
        <div className={styles.innerDot} style={{ backgroundColor: color }} />
      </div>
    </Text>
  )
}

Status.propTypes = propTypes
Status.defaultProps = defaultProps

export default Status
