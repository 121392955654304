import React, { useState } from 'react'
import { Menu } from 'antd'
import {
  FileSearchOutlined,
  CalendarOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'

const UserNavBar = () => {
  const location = useLocation();
  const thisLocation = location.pathname==="/"?"/appointment":location.pathname

  const [collapsed, setCollapsed] = useState({ current:thisLocation })
  const handleClick = e => {
    setCollapsed({ current: e.key })
  }
  
 
  return (
    <div>
      <Menu onClick={handleClick} selectedKeys={collapsed.current} mode="horizontal" style={{ textAlign: 'center' }}>
        <Menu.Item key="/appointment" icon={<CalendarOutlined />}>
        <Link to='/appointment'> Consultas </Link>
        </Menu.Item>
        <Menu.Item key="/invoices" icon={<FileSearchOutlined />} >
         <Link to='/invoices'> Boletas </Link>
        </Menu.Item>
        <Menu.Item key="/uploadinvoices" icon={<DownloadOutlined />}>
          <Link to="/uploadinvoices">Carga Masiva</Link>
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default UserNavBar
