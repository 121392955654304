import { gql } from 'apollo-boost'

const GET_APPOINTMENTS = gql`
  query getAppointments(
    $selectedProfessionals: [ID]
    $selectedDepartments: [ID]
    $from: Date
    $to: Date
  ) {
    allAppointments(
      professionals: $selectedProfessionals
      departments: $selectedDepartments
      from: $from
      to: $to
      pagination: { limit: 500 }
    ) {
      _id
      room
      patient {
        _id
        firstname
        lastname
        birthdate
        email
        phone
        rut
        passport
      }
      professional {
        _id
        firstname
        lastname
        departments {
          _id
          name
        }
      }
      admin {
        _id
        firstname
        lastname
      }
      review {
        patient {
          comment
          issues
          rating
        }
        professional {
          comment
          issues
        }
      }
      hour
      minute
      room
      scheduledAt
      attendedAt
      confirmedAt
      closedAt
      clcAppointmentId
      clcPatientId
    }
  }
`
export { GET_APPOINTMENTS }
