import React from 'react'

import { Typography } from 'antd'
import StatusIndicatorComponent from 'App/Components/StatusIndicator'

const { Text } = Typography

const StatusIndicator = ({
  appointment,
  styles,
  keyProp,
  status,
  connectionStatus,
  handleDescriptionItem,
}) => {
  return (
    <div className={styles.bodyListStateAndDoctorContainer} key={`1${keyProp}`}>
      <span
        className={styles.bodyListStates}
        onClick={() => {
          if (appointment) handleDescriptionItem(appointment)
        }}>
        <StatusIndicatorComponent
          color={status[connectionStatus].color}
          connectionStatus={connectionStatus}
          Text={Text}
        />
      </span>
      <span
        className={styles.bodyListDoctor}
        onClick={() => {
          if (appointment) handleDescriptionItem(appointment)
        }}>
        Dr. {appointment.professional.firstname}{' '}
        {appointment.professional.lastname}
      </span>
    </div>
  )
}

export default StatusIndicator
