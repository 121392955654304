import React from 'react'
import { Button } from 'antd'
import styles from './index.module.css'
import { ArrowLeftOutlined } from '@ant-design/icons'

const AppintmentDescriptionHeader = ({ setShowAppointmentDescription }) => {
  return (
    <>
      <div className={styles.menuHeaderTitleContentDescription}>
        <h1
          className={styles.menuHeaderAppointmentTitle}
          style={{ marginLeft: '0px' }}>
          Consulta
        </h1>
      </div>
      <div className={styles.menuHeaderBackButton}>
        <Button
          shape="round"
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            setShowAppointmentDescription(false)
          }}>
          Volver al Listado
        </Button>
      </div>
    </>
  )
}

export default AppintmentDescriptionHeader
