import { useQuery } from 'react-apollo'
import { GET_UPLOADS } from 'App/Queries/AppointmentDescriptionItem'

const useGetUploads = room => {
  const { data, loading, error, refetch } = useQuery(GET_UPLOADS, {
    variables: { room },
    fetchPolicy: 'network-only',
  })

  return {
    data,
    loading,
    error,
    refetch,
  }
}

export default useGetUploads
