import axios from 'axios'
const crypto = require('crypto')

const UseUpdateContactData = async appointment => {
  if (appointment.clcPatientId !== null) {
    const { patient } = appointment

    patient.rut = patient.rut.replace(/[^\w\s]/gi, '')

    const dataUpdate = {
      PersCorrel: patient.rut,
      Telefono: 'sin teléfono fijo',
      Celular: patient.phone,
      Email: patient.email.toLowerCase(),
    }

    const key = '@#CLCRokket$2020#@'
    const sign = `PersCorrel=${dataUpdate.PersCorrel}&Email=${dataUpdate.Email}`
    const signSha1 = crypto.createHmac('sha1', key).update(sign).digest('hex')

    dataUpdate.Firma = signSha1

    try {
      await axios.post(
        `${process.env.REACT_APP_WS_AGENDA_ROKKET_URL}/ActualizarDatosContacto`,
        dataUpdate,
      )
    } catch (error) {
      console.log(error)
    }
  }

  return true
}

export default UseUpdateContactData
