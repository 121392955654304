import { useEffect } from 'react'
import { notification } from 'antd'

const UsePatientArrived = (realtime, appointment, setChecked, timeNow) => {
  useEffect(() => {
      const showAppointmentByTime = (appointment.hour >= timeNow - 3 && appointment.hour <= timeNow + 3) || (timeNow === 0)
      if (realtime && appointment && !appointment.confirmedAt && showAppointmentByTime) {

        const appointChannel = realtime.channels.get(
          `appointment:${appointment.room}`,
        )

        appointChannel.subscribe('patient_arrived', data => {
          setChecked(true)
        })

        appointChannel.subscribe('error_patient_arrived', data => {
          notification.open({
            message: 'Advertencia!',
            description: `Hubo un problema marcando el llegó para el paciente ${data.patient}, favor notificar a soporte de telemedicina en el chat que aparece en la esquina inferior derecha`
          })
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime, appointment])
}

export default UsePatientArrived
