import React from 'react'

import { ConfirmationController } from './Components'

import {
  StatusIndicator,
  Departments,
  SendPatientAlert,
  ResendEmail,
} from './Components'

const ItemHasAppointment = ({
  appointment,
  styles,
  keyProp,
  showPatientAlertModal,
  showPatientEmailModal,
  getAppointmentData,
  handleDescriptionItem,
  status,
  connectionStatus,
  timeNow
}) => {
  return (
    <>
      {appointment ? (
        <>
          <StatusIndicator
            appointment={appointment}
            styles={styles}
            keyProp={keyProp}
            status={status}
            connectionStatus={connectionStatus}
            handleDescriptionItem={handleDescriptionItem}
          />

          <Departments
            styles={styles}
            appointment={appointment}
            handleDescriptionItem={handleDescriptionItem}
            keyProp={keyProp}
          />

          <div className={styles.bodyListActionContainer} key={`3${keyProp}`}>
            <SendPatientAlert
              appointment={appointment}
              styles={styles}
              getAppointmentData={getAppointmentData}
              showPatientAlertModal={showPatientAlertModal}
            />
            <ResendEmail
              appointment={appointment}
              getAppointmentData={getAppointmentData}
              showPatientEmailModal={showPatientEmailModal}
            />
            <div>
              <ConfirmationController
                className={styles.confirmationController}
                defaultValue={!!appointment.confirmedAt}
                appointmentId={appointment._id}
                appointment={appointment}
                Text={Text}
                timeNow={timeNow}
              />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            color: '#C2CAE1',
            width: '95%',
            textAlign: 'right',
            paddingRight: '0.8%',
          }}>
          {' '}
          Libre
        </div>
      )}
    </>
  )
}

export default ItemHasAppointment
