import React from 'react'

import { Button, Tooltip } from 'antd'
import { AlertOutlined } from '@ant-design/icons'

const SendPatientAlert = ({
  appointment,
  styles,
  getAppointmentData,
  showPatientAlertModal,
}) => {
  return (
    <Tooltip title="Enviar Alerta" color={'#2D3F8C'}>
      <Button
        className={styles.lateMessageButton}
        shape="circle"
        icon={<AlertOutlined />}
        onClick={() => {
          getAppointmentData(appointment)
          showPatientAlertModal()
        }}
      />
    </Tooltip>
  )
}

export default SendPatientAlert
