import getAuthToken from 'App/Helpers/getAuthToken'

export default async (url, options) => {
  if (!options.headers) options.headers = {}

  const token = await getAuthToken()
  options.headers.Authorization = `Bearer ${token}`

  try {
    const result = await fetch(url, options)
    return result
  } catch (error) {
    console.warn('GraphQL request error:', error)
    throw error
  }
}
