import React, { useState } from 'react'
import { Button, Modal, notification } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { Client2 as InvoiceClient } from '../../../ApolloClient/index'
import { SEND_EMAILS_INVOICES } from '../Graphql/Mutators/EmailMutators'

import styles from './index.module.css'

const SendEmail = ({
  warning,
  setWarning,
  message,
  setMessagge,
  modalSendEmails,
  setModalSendEmails,
  checkedList,
  handleSearchEvent,
}) => {
  const [loading, setLoading] = useState(false)
  const [sendMultipleInvoiceEmail] = useMutation(SEND_EMAILS_INVOICES, {
    client: InvoiceClient,
  })
  const handleOkSendEmail = async () => {
    let title = checkedList.length<2?'¿Está seguro de continuar con el envío del correo electrónico?':`¿Está seguro de continuar con el envío de los correos (${checkedList.length})?`
    Modal.confirm({
      title:title,
      okText: 'Continuar',
      autoFocusButton: 'cancel',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        className: "ant-btn ant-btn-primary ant-btn-dangerous"
      },
      async onOk() {
        setLoading(true)
        try {
          const response = await sendMultipleInvoiceEmail({
            variables: { invoicesIdList: checkedList },
          })
          if (response.data.sendMultipleInvoiceEmail.success === false) {
            notification.error({
              message:
                'Hubo un problema enviando las boletas, por favor revise el correo de los pacientes e inténtelo de nuevo.',
            })
          } else {
            notification.success({
              message: 'Boletas enviadas',
            })
            setLoading(false)
            handleSearchEvent()
          }
        } catch (err) {
          notification.error({
            message:
              'Hubo un problema enviando las boletas, por favor revise el correo de los pacientes e inténtelo de nuevo.',
          })
          console.log(err)
          setLoading(false)
        }
        setLoading(false)
        setModalSendEmails(false)
        setMessagge('')
        setWarning('')
      },
    })
  }

  const handleCancelSendEmail = () => {
    setModalSendEmails(false)
    setMessagge('')
    setWarning('')
  }

  return (
    modalSendEmails && (
      <Modal
        title="Envío de Boletas Seleccionadas"
        visible={modalSendEmails}
        onCancel={handleCancelSendEmail}
        onOk={handleOkSendEmail}
        footer={[
          <Button
            type="primary"
            key="back"
            onClick={handleCancelSendEmail}
            danger
            disabled={loading}
            autoFocus>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOkSendEmail}>
            Enviar
          </Button>,
        ]}>
        {warning.length>0?<p className={styles.warningMessage}>{warning}</p>:''}
        <p>{message}</p>
      </Modal>
    )
  )
}

export default SendEmail
