import { gql } from 'apollo-boost'

export const SEND_EMAIL_INVOICE = gql`
mutation sendSingleInvoiceEmail($invoiceId: String!, $email: String) {
  sendSingleInvoiceEmail(invoiceId: $invoiceId, email: $email) {
    success
    message
  }
}
`

export const SEND_EMAILS_INVOICES = gql`
mutation sendMultipleInvoiceEmail($invoicesIdList: [String] ) {
  sendMultipleInvoiceEmail(invoicesIdList: $invoicesIdList) {
    success
    message
  }
}
`
