import Auth from '@aws-amplify/auth'

export default async () => {
  const user = await Auth.currentAuthenticatedUser()

  console.log(user.signInUserSession.idToken.jwtToken)

  return (
    user && user.signInUserSession && user.signInUserSession.idToken.jwtToken
  )
}
