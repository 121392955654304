export const appointmentTypes = {
  PATIENT_NOTIFIED_CREATED: {
    description: 'Paciente Notificado',
  },
  PATIENT_NOTIFIED_RESENT: {
    description: 'Notificación Reenviada',
  },
  PATIENT_NOTIFIED_DELAY: {
      description: 'PATIENT_NOTIFIED_DELAY'
  },
  PATIENT_DISCONNECTED: { description: 'Desconectado' },
  PATIENT_CONNECTED: { description: 'Conectado' },
  PATIENT_REMOVED_ATTACHMENT: { description: 'Elimina Archivo Adjunto' },
  PATIENT_ADDED_ATTACHMENT: {
    description: 'Añade Archivo Adjunto',
  },
  PATIENT_JOINED_ROOM: { description: 'Ingresa a la Sala' },
  PATIENT_ANSWERED_QUIZ: {
    description: 'Respondió Encuesta',
  },
  PATIENT_REVIEWED: {
    description: 'Consulta Calificada',
  },
  PATIENT_LEFT_ROOM: {
    description: 'Abandona la Sala',
  },
  PROFESSIONAL_DISCONNECTED: { description: 'Desconectado' },
  PROFESSIONAL_CONNECTED: { description: 'Conectado' },
  PROFESSIONAL_JOINED_ROOM: { description: 'Ingresa a la Sala' },
  PROFESSIONAL_REVIEWED: {
    description: 'Consulta Calificada',
  },
  PROFESSIONAL_LEFT_ROOM: {
    description: 'Abandona la Sala',
  },

  //   # Consultations
  PROFESSIONAL_TO_NOTIFIED_CREATED: {
    description: 'TO_NOTIFIED_CREATED',
  },
  PROFESSIONAL_TO_DISCONNECTED: {
    description: 'PROFESSIONAL_TO_DISCONNECTED',
  },
  PROFESSIONAL_TO_CONNECTED: {
    description: 'PROFESSIONAL_TO_CONNECTED',
  },
  PROFESSIONAL_TO_JOINED_ROOM: {
    description: 'PROFESSIONAL_TO_JOINED_ROOM',
  },
  PROFESSIONAL_TO_LEFT_ROOM: {
    description: 'PROFESSIONAL_TO_LEFT_ROOM',
  },
  PROFESSIONAL_FROM_DISCONNECTED: {
    description: 'PROFESSIONAL_FROM_DISCONNECTED',
  },
  PROFESSIONAL_FROM_CONNECTED: {
    description: 'PROFESSIONAL_FROM_CONNECTED',
  },
  PROFESSIONAL_FROM_JOINED_ROOM: {
    description: 'PROFESSIONAL_FROM_JOINED_ROOM',
  },
  PROFESSIONAL_FROM_LEFT_ROOM: {
    description: 'PROFESSIONAL_FROM_LEFT_ROOM',
  },

  //   # General
  UNCONFIRMED: {
    description: 'CONSULTA NO CONFIRMADA',
  },
  ATTENDED: {
    description: 'INICIO CONSULTA',
  },
  DELETED: { description: 'CONSULTA ELIMINADA' },
  CREATED: {
    description: 'Consulta Creada',
  },
  CONFIRMED: {
    description: 'PACIENTE LLEGÓ',
  },
  CLOSED: { description: 'FIN CONSULTA' },
}
