import React, { useCallback, useState, useEffect, useContext } from 'react'

import { Checkbox, Spin, Typography } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { CONFIRM_APPOINTMENT, UNCONFIRM_APPOINTMENT } from 'App/Mutators'

import { UsePatientArrived } from '../../../../Services'
import { AblyContext } from 'App/Providers'

const propTypes = {}

const defaultProps = {}

const { Text } = Typography

const ConfirmationController = ({
  defaultValue,
  appointmentId,
  appointment,
  timeNow
}) => {
  const [checked, setChecked] = useState(defaultValue)
  const [spin, setSpin] = useState(false)
  const { realtime } = useContext(AblyContext)

  UsePatientArrived(realtime, appointment, setChecked, timeNow)
  useEffect(() => setChecked(!!defaultValue), [defaultValue])

  const [confirmAppointment] = useMutation(CONFIRM_APPOINTMENT)

  const [unconfirmAppointment] = useMutation(UNCONFIRM_APPOINTMENT)

  const handleChange = useCallback(
    async e => {
      setChecked(e.target.checked)
      setSpin(true)
      if (e.target.checked)
        try {
          // UsePatientArrivedSoap(appointmentId)
          await confirmAppointment({ variables: { id: appointmentId } })
          setSpin(false)
          setChecked(true)
        } catch (err) {
          console.log('Error confirming appointment', err)
          setSpin(false)
          setChecked(false)
        }
      else
        try {
          await unconfirmAppointment({ variables: { id: appointmentId } })
          setSpin(false)
        } catch (err) {
          console.log('Error unconfirming appointment', err)
          setChecked(true)
        }
    },
    [unconfirmAppointment, confirmAppointment, appointmentId],
  )

  return (
    <Text
      style={{
        width: '100%',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {spin ? (
        <div style={{ flex: 1, textAlign: 'right' }}>
          <Spin size="small" style={{ paddingRight: '2.9vw' }} />
        </div>
      ) : (
        <Checkbox
          checked={checked}
          onChange={handleChange}
          style={{ flex: 1, textAlign: 'right' }}
        />
      )}
    </Text>
  )
}

ConfirmationController.propTypes = propTypes
ConfirmationController.defaultProps = defaultProps

export default ConfirmationController
