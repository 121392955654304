import dayjs from 'dayjs'
import { range } from 'ramda'

export default n => {
    const hours = []
    const now = dayjs()
    const dayHours = range(8, 24)
    dayHours.forEach(i => {
      for(let j = 0; j < 60; j+=5)
        hours.push(
          now.hour(i).minute(j).second(0).millisecond(0).format('HH:mm')
        )
    })
    return hours
  }