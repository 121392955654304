import React, { useState } from 'react'
import { DatePicker } from 'antd'
import { CalendarTwoTone } from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/es'
import dayjs from 'dayjs'
import localization from 'dayjs/locale/es'
import DepartmentsSelect from 'App/Components/DepartmentsSelect'
import ProfessionalSelect from 'App/Components/ProfessionalSelect'
import styles from './index.module.css'

const AppointmentDefaultMenu = ({
  selectedDepartments,
  selectedProfessionals,
  fetchProfessionals,
  fetchDepartments,
  setSelectedDepartments,
  setSelectedProfessionals,
  setDepartmentsIds,
  setProfessionalsIds,
  professionalsIds,
  departmentsIds,
  setDatePicker,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [dayChooseInDatePicker, setDayChooseInDatePicker] = useState(null)
  const [dayName, date] = dayjs()
    .locale('es', localization)
    .format(`dddd, D [de] MMMM`)
    .split(',')

  const todayYear = moment().year()
  const todayMonth =
    moment().month() + 1 < 10
      ? `0${moment().month() + 1}`
      : moment().month() + 1
  const todayDay =
    moment().date() < 10 ? `0${moment().date()}` : moment().date()
  const today = `${todayYear}-${todayMonth}-${todayDay}`

  const handlerDatePicker = e => {
    const year = e.year()
    const month = e.month() + 1 < 10 ? `0${e.month() + 1}` : e.month() + 1
    const day = e.date() < 10 ? `0${e.date()}` : e.date()
    const nextDay = e.date() + 1 < 10 ? `0${e.date() + 1}` : e.date() + 1

    if (today === `${year}-${month}-${day}`)
      setDayChooseInDatePicker(e.locale('es').format('[Hoy] dddd, D [de] MMMM'))
    else
      setDayChooseInDatePicker(e.locale('es').format('[Día] dddd, D [de] MMMM'))

    setDatePicker([`${year}-${month}-${day}`, `${year}-${month}-${nextDay}`])
  }

  const handleHiddenDatePicker = () => setShowDatePicker(false)

  return (
    <>
      <div className={styles.menuHeaderTitleContent}>
        <div
          style={{ cursor: 'pointer', width: '100%' }}
          onBlur={handleHiddenDatePicker}
          onScroll={handleHiddenDatePicker}
          onMouseLeave={handleHiddenDatePicker}
          onClick={() => {
            setShowDatePicker(!showDatePicker)
          }}>
          <h1 className={styles.menuHeaderAppointmentTitle}>
            Consultas{' '}
            <DatePicker
              onChange={handlerDatePicker}
              style={{ width: '1px', visibility: 'hidden' }}
              open={showDatePicker}
              size="large"
              inputReadOnly
              bordered={false}
            />
          </h1>
          <h2 style={{ margin: '0' }} className={styles.textAlignResponsive}>
            <CalendarTwoTone className={styles.date} />
            <p
              style={{
                display: 'inline-block',
                padding: '0px 10px',
                color: '#6B7CFF',
              }}>
              <b className={styles.date}>
                {dayChooseInDatePicker !== null
                  ? `${dayChooseInDatePicker}`
                  : `Hoy ${dayName},${date}`}
              </b>
            </p>
          </h2>
        </div>
      </div>
      <div className={styles.menuHeaderAppointmentSelects}>
        <DepartmentsSelect
          fetchDepartments={fetchDepartments}
          setSelectedDepartments={setSelectedDepartments}
          disabled={disabled}
          selectedProfessionals={selectedProfessionals}
          setDisabled={setDisabled}
          setDepartmentsIds={setDepartmentsIds}
          departmentsIds={departmentsIds}
          professionalsIds={professionalsIds}
        />
        <ProfessionalSelect
          fetchProfessionals={fetchProfessionals}
          selectedProfessionals={selectedProfessionals}
          selectedDepartments={selectedDepartments}
          setSelectedProfessionals={setSelectedProfessionals}
          setDisabled={setDisabled}
          setProfessionalsIds={setProfessionalsIds}
          professionalsIds={professionalsIds}
          departmentsIds={departmentsIds}
        />
      </div>
    </>
  )
}

export default AppointmentDefaultMenu
