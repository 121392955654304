import React, { useEffect, useState } from 'react'
import { Row, Col, Spin } from 'antd'
import styles from '../index.module.css'

const DescriptionAttachmentFiles = ({ uploadsData, uploadsLoading }) => {
  const [filesQuantity, setFilesQuantity] = useState(null)
  useEffect(() => {
    const ifExistQuantity = async ({ uploads }) => {
       setFilesQuantity(uploads.length)
    }
    if (uploadsData && uploadsData.uploads && !filesQuantity) 
      ifExistQuantity(uploadsData)
  }, [uploadsData, filesQuantity])

  return (
    <Row className={styles.descriptionItemCard}>
      <Col span={24} className={styles.descriptionItemTitle}>
        Archivos Adjuntos:
      </Col>
      <Col span={24} className={styles.descriptionItemBody}>
        {uploadsLoading ? <Spin>Leyendo archivos...</Spin> : (
          filesQuantity ? (
            <span style={{ marginLeft: '10px' }}>
              {`${filesQuantity}`} {filesQuantity > 1 ? 'archivos.' : 'archivo.'}
            </span>
          ) : (<span style={{ marginLeft: '10px' }}>{`0 archivos.`}</span>)
        )}
      </Col>
    </Row>
  )
}

export default DescriptionAttachmentFiles
