import React from 'react'
import AppointmentDefaultMenu from './AppointmentDefaultMenu'
import AppointmentDescriptionMenu from './AppointmentDescriptionMenu'

import styles from './index.module.css'

const AppointmentMenu = ({
  selectedDepartments,
  selectedProfessionals,
  fetchProfessionals,
  fetchDepartments,
  setSelectedDepartments,
  setSelectedProfessionals,
  setDepartmentsIds,
  setProfessionalsIds,
  professionalsIds,
  departmentsIds,
  setDatePicker,
  showAppointmentDescription,
  setShowAppointmentDescription,
}) => {
  return (
    <div className={styles.appointmentMenuHeader}>
      <div className={styles.menuHeaderTitlesContainer}>
        {showAppointmentDescription ? (
          <AppointmentDescriptionMenu
            setShowAppointmentDescription={setShowAppointmentDescription}
          />
        ) : (
          <AppointmentDefaultMenu
            selectedDepartments={selectedDepartments}
            selectedProfessionals={selectedProfessionals}
            fetchProfessionals={fetchProfessionals}
            fetchDepartments={fetchDepartments}
            setSelectedDepartments={setSelectedDepartments}
            setSelectedProfessionals={setSelectedProfessionals}
            setDepartmentsIds={setDepartmentsIds}
            setProfessionalsIds={setProfessionalsIds}
            professionalsIds={professionalsIds}
            departmentsIds={departmentsIds}
            setDatePicker={setDatePicker}
          />
        )}
      </div>
    </div>
  )
}

export default AppointmentMenu
