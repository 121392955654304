import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select, Spin } from 'antd'
import useStateWithLocalStorage from 'App/Hooks/useStateWithLocalStorage'

const { Option } = Select

const propTypes = {
  fetchProfessionals: PropTypes.func.isRequired,
}

const defaultProps = {}

const ProfessionalSelect = ({
  fetchProfessionals,
  selectedDepartments,
  selectedProfessionals,
  setSelectedProfessionals,
  setDisabled,
  setProfessionalsIds
}) => {
  let tempProfessionalsIds = []
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [professionalsNames, setProfessionalsNames] = useStateWithLocalStorage('professionalsNames')

  const handleSearch = useCallback(
    async filter => {
      setLoading(true)
      setData([])
      const data = await fetchProfessionals(filter)
      setData(data)
      setLoading(false)
    },
    [fetchProfessionals],
  )

  const handleChangeProfessional = value => {
    setSelectedProfessionals([
      ...value
    ])
  }

  const handleClick = () => {
    if (tempProfessionalsIds.length === 0 && selectedDepartments.length === 0)
      setDisabled(true)
  }

  const handleBlur = () => {
    if (selectedProfessionals.length === 0 && selectedDepartments.length === 0)
      setDisabled(false)
  }

  const handleDeselect = () => {
    if (tempProfessionalsIds.length === 0 && selectedDepartments.length === 0)
      setDisabled(false)
  }

  const handleChange = (...args) => {
    tempProfessionalsIds = []
    args[1].map(i => (
      tempProfessionalsIds.push(i.id)
    ))
    setProfessionalsNames(args[0])
    setProfessionalsIds(tempProfessionalsIds)
    handleChangeProfessional(tempProfessionalsIds)
  }

  useEffect(() => {
    handleSearch('')
  }, [handleSearch])

  return (
    <Select
      autoClearSearchValue
      mode='multiple'
      defaultValue={professionalsNames.length > 1 ? professionalsNames.toString().split(',') : professionalsNames}
      style={{ width: '100%', padding: '5px' }}
      placeholder='Seleccione Profesionales'
      notFoundContent={loading ? <Spin size="small" /> : null}
      onChange={handleChange}
      onClick={handleClick}
      onBlur={handleBlur}
      onDeselect={handleDeselect}
    >
      {data.map(i => (
        <Option key={i.value} id={i.id}>{i.label}</Option>
      ))}
    </Select>
  )
}

ProfessionalSelect.propTypes = propTypes
ProfessionalSelect.defaultProps = defaultProps

export default ProfessionalSelect
