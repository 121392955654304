import { gql } from 'apollo-boost'

const GET_INVOICES = gql`
  query getInvoices(
      $startDate: String,
      $endDate: String,
      $sent: Boolean!,
      $notSent: Boolean!
    ) {
        getInvoices(
        startDate: $startDate,
        endDate: $endDate
        sent: $sent,
        notSent: $notSent
      )
      {
        invoiceId
        invoiceState
        patientName
        patientRut
        patientEmail
        appointmentDate
        professionalName
        shippingAt
      }
    }
`

export { GET_INVOICES }
