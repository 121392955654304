import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import styles from '../index.module.css'

const DescriptionGeneralInfo = ({ appointment }) => {
  const [patient, setPatient] = useState(null)
  const [doctor, setDoctor] = useState(null)

  const parseTime = (hour, minute) => {
    const hr = hour < 10 ? `0${hour}` : hour
    const mn = minute < 10 ? `0${minute}` : minute
    return `${hr}:${mn}`
  }

  const parseDateByCaracter = (date, caracter) => {
    if (date) {
      const splitTimeZoneAndDate = date.split('T')
      const splitDate = splitTimeZoneAndDate[0].split('-')
      const year = splitDate[0]
      const month = splitDate[1]
      const day = splitDate[2]
      return year + caracter + month + caracter + day
    }
  }

  useEffect(() => {
    if (appointment) {
      setPatient(appointment.patient)
      setDoctor(appointment.professional)
    }
  }, [appointment])

  return (
    <Row className={styles.descriptionItemCard}>
      <Col span={24} className={styles.descriptionItemTitle}>
        Datos Generales:
      </Col>
      <Col span={24} className={styles.descriptionItemBody}>
        <Row>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Paciente:{' '}
              <span className={styles.spanGeneralInfoData}>
                {patient && `${patient.firstname} ${patient.lastname}`}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              {patient ? (patient.passport ? 'Pasaporte' : 'Rut') : 'Pasaporte'}
              <span className={styles.spanGeneralInfoData}>
                {patient && (patient.passport ? patient.passport : patient.rut)}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Fecha de Nacimiento:{' '}
              <span className={styles.spanGeneralInfoData}>
                {patient &&
                  patient.birthdate &&
                  parseDateByCaracter(patient.birthdate, '/')}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Teléfono:{' '}
              <span className={styles.spanGeneralInfoData}>
                {patient && patient.phone}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Correo:{' '}
              <span className={styles.spanGeneralInfoEmail}>
                {patient && patient.email}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Doctor:{' '}
              <span
                className={styles.spanGeneralInfoData}
                style={{ textTransform: 'uppercase' }}>
                Dr. {doctor && `${doctor.firstname} ${doctor.lastname}`}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Especialidad:{' '}
              {doctor && doctor.departments && doctor.departments.length > 0 ? (
                doctor.departments.map(department => {
                  return (
                    <>
                      <span
                        key={department.name}
                        className={styles.spanGeneralInfoData}
                        style={{ marginLeft: '2px' }}>
                        {' '}
                        {department.name}
                      </span>
                      <span className={styles.addCommaSeparator}>{', '}</span>
                    </>
                  )
                })
              ) : (
                <span
                  className={styles.spanGeneralInfoData}
                  style={{ textTransform: 'uppercase' }}>
                  {'Ninguna asignada'}
                </span>
              )}
            </p>
          </Col>
          <Col span={24}>
            <p className={styles.paragraphGeneralInfo}>
              Hora Asignada:{' '}
              <span className={styles.spanGeneralInfoData}>
                {appointment && parseTime(appointment.hour, appointment.minute)}{' '}
                hrs
              </span>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DescriptionGeneralInfo
