import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'
import { GET_USER } from 'App/Queries'

let UserContext
const { Provider, Consumer } = (UserContext = createContext())

const UserProvider = ({ children }) => {
  const location = useLocation()
  const [user, setUser] = useState({})

  const { loading, data, refetch } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const firstname = get(data, 'session.firstname', '')
    const lastname = get(data, 'session.lastname', '')

    setUser({
      firstname,
      lastname,
    })
  }, [data, loading])

  useEffect(() => {
    try {
      refetch()
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }, [location, refetch])

  return <Provider value={{ user }}>{children}</Provider>
}

export { UserProvider, Consumer as UserConsumer, UserContext }
