import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { UserContext } from 'App/contexts/UserContext'

const IntercomApp = ({ children }) => {
  const location = useLocation()
  const { user } = useContext(UserContext)

  const boot = () => {
    window.Intercom('boot', {
      app_id: 'd6637657',
      type: 'secretary',
    })
  }
  const shutdown = () => {
    window.Intercom('shutdown')
  }

  useEffect(() => {
    boot()
  }, [])

  useEffect(() => {
    const payload = {
      name: user.firstname,
    }

    Auth.currentSession()
      .then(data => {
        const { email } = data.idToken.payload
        window.Intercom('update', { ...payload, email })
      })
      .catch(() => {
        shutdown()
        boot()
      })
  }, [location, user])

  return <>{children}</>
}

export default IntercomApp
