import React from 'react'
import { withRouter } from 'react-router-dom'
import UserDropdown from './UserDropDown'
import UserNavBar from './UserNavBar'
import { Header as HeaderParts } from '@clinicalascondes/clc-parts'

const Header = () => {
  return <HeaderParts rightComponent={<UserDropdown />} middleComponent={<UserNavBar />} />
}

export default withRouter(Header)
