/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { Modal, Input, Form, notification, Button, Spin } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import styles from './index.module.css'
import dayjs from 'dayjs'
import { RESEND_APPOINTMENT, UPDATE_PATIENT } from 'App/Mutators'

import { UseUpdateContactData } from './Services'

const EmailModal = ({ isOpen, appointment, showEmailModal }) => {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const [hour, setHour] = useState(null)
  const [minute, setMinute] = useState(null)
  const [patient, setPatient] = useState(null)
  const [_id, setId] = useState(null)
  const [newEmail, setNewEmail] = useState(null)
  const [validateStatus, setValidateStatus] = useState('success')
  const [resendAppointmentMutator] = useMutation(RESEND_APPOINTMENT)
  const [updatePatientMutator] = useMutation(UPDATE_PATIENT)
  const [loadingResend, setLoadingResend] = useState(false)

  useEffect(() => {
    if (appointment) {
      setHour(appointment.hour)
      setMinute(appointment.minute)
      setPatient(appointment.patient)
      setId(appointment.patient._id)
      setNewEmail(appointment.patient.email)
    }
  }, [appointment])

  const handleEmail = e => {
    const email = e.target.value
    setNewEmail(email)
    if (re.test(email)) setValidateStatus('success')
    else setValidateStatus('error')
  }

  const resendEmail = async () => {
    try {
      await resendAppointmentMutator({ variables: { _id: appointment._id } })
      notification.success({
        message: 'Email enviado y actualizado exitosamente',
      })
    } catch (err) {
      notification.error({
        message:
          'Hubo un problema enviando el email, por favor revise el correo del paciente e inténtelo de nuevo.',
      })
      console.log(err)
    }
  }

  const updatePatient = async () => {
    setLoadingResend(true)
    try {
      const response = await UseUpdateContactData(appointment)
      if (response) {
        await updatePatientMutator({
          variables: { _id, input: { email: newEmail } },
        })
        resendEmail()
        showEmailModal()
        setLoadingResend(false)
      } else {
        setLoadingResend(false)
        notification.error({
          message:
            'Hubo un problema realizando la acción, por favor inténtelo de nuevo.',
        })
      }
    } catch (err) {
      setLoadingResend(false)
      notification.error({
        message:
          'Hubo un problema realizando la acción, por favor inténtelo de nuevo.',
      })
      return err
    }
  }

  return (
    isOpen && (
      <Modal
        centered
        title="Reenviar correo"
        visible={isOpen}
        onOk={updatePatient}
        onCancel={showEmailModal}
        footer={[
          <Button
            className={styles.cancelButton}
            key="back"
            onClick={showEmailModal}>
            Cancelar
          </Button>,
          <Button
            className={styles.saveButton}
            key="submit"
            type="primary"
            disabled={validateStatus !== 'success'}
            onClick={updatePatient}>
            {loadingResend ? <Spin /> : 'Reenviar'}
          </Button>,
        ]}>
        {patient && (
          <>
            <h3>
              {patient.firstname} {patient.lastname}
            </h3>

            {patient.rut && (
              <div>
                <b>Rut: </b>
                {patient.rut}
              </div>
            )}
            {patient.passport && (
              <div>
                <b>Pasaporte: </b>
                {patient.passport}
              </div>
            )}

            <div>
              <b>Fecha de nacimiento: </b>
              {dayjs(patient.birthdate).format('DD/MM/YYYY')}
            </div>
            <div>
              <b>Teléfono: </b>
              {patient.phone}
            </div>
            <div>
              <b>Hora: </b>
              {hour.toString().length === 1 ? `0${hour}` : hour}:
              {minute.toString().length === 1 ? `0${minute}` : minute}
            </div>
            <div>
              <b>Email:</b>
              <Form>
                <Form.Item hasFeedback validateStatus={validateStatus}>
                  <Input
                    type="email"
                    style={{ width: '100%' }}
                    value={newEmail}
                    onChange={handleEmail}
                  />
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </Modal>
    )
  )
}

export default EmailModal
