import React from 'react'
import { List, Checkbox } from 'antd'
import styles from '../index.module.css'
import ResendEmail from './Components/ResendEmail'

const { Item } = List

const InvoiceItem = ({
  keyProp,
  item,
  handleFunction,
}) => {
  return (
    <div className={styles.appointmentListBodyContainer} key={keyProp}>
      <Item className={styles.bodyListContainer}>
        <div className={styles.headerListHourContainer}>
          <span >
            <Checkbox
            type="checkbox"
            value={item.invoiceId}
            defaultChecked={false}
            />
          </span>
        </div>
        <div className={styles.headerListStateAndDoctorContainer}>
          <span className={styles.headerListState}>{item.patientName}</span>
          <span className={styles.headerListState}>{item.patientRut}</span>
        </div>
        <div className={styles.headerListPatientAndSpeciallityContainer}>
          <span className={styles.headerListState}>{item.appointmentDate}</span>
          <span className={styles.headerListState}>{item.shippingAt!==null?item.shippingAt:'No Enviado'}</span>
          <span className={styles.headerListState}>{item.professionalName}</span>
        </div>
        <div className={styles.headerListActionContainer}>
          <span>{item.invoiceState}</span>
        </div>
        <div className={styles.headerListActionContainer}>
          <span>
            <ResendEmail invoice={item} handleFunction={handleFunction}/>
          </span>
        </div>
      </Item>
    </div>
  )
}

export default InvoiceItem
