import React, { useState, useEffect } from 'react'
import { List, Typography } from 'antd'
import { ItemHasAppointment } from './Components'
import styles from './index.module.css'

const status = {
  done: {
    color: '#5974AF',
    label: 'Finalizado',
  },
  connected: {
    color: '#52C41A',
    label: 'Conectado',
  },
  waiting: {
    color: '#faad14',
    label: 'Esperando',
  },
  disconnected: {
    color: '#979797',
    label: 'Desconectado',
  },
  inProgress: {
    color: '#FF2B51',
    label: 'En consulta',
  },
}
const { Item } = List
const { Text } = Typography
const AppointmentItem = ({
  appointment,
  time,
  waitingRoomUsers,
  appointUsers,
  showPatientAlertModal,
  showPatientEmailModal,
  getAppointmentData,
  handleDescriptionItem,
  keyProp,
  timeNow,
}) => {
  const [connectionStatus, setConnectionStatus] = useState('inProgress')

  useEffect(() => {
    if (appointment)
      if (appointment.closedAt) setConnectionStatus('done')
      else {
        const isInAppointment = appointUsers.find(
          p => p.clientId === appointment.patient._id,
        )
        if (isInAppointment) setConnectionStatus('inProgress')
        else {
          const isOnline = waitingRoomUsers.find(
            p => p.clientId === appointment.patient._id,
          )
          if (isOnline && isOnline.data === 'waiting')
            setConnectionStatus('waiting')
          else setConnectionStatus(isOnline ? 'connected' : 'disconnected')
        }
      }
    else setConnectionStatus('disconnected')
  }, [appointUsers, appointment, waitingRoomUsers])

  return (
    <div className={styles.appointmentListBodyContainer} key={keyProp}>
      <Item className={styles.bodyListContainer}>
        <div className={styles.bodyListHourContainer}>
          <Text className={styles.time}>
            {time} <span>hrs.</span>
          </Text>
        </div>
        <ItemHasAppointment
          appointment={appointment}
          styles={styles}
          keyProp={keyProp}
          showPatientAlertModal={showPatientAlertModal}
          showPatientEmailModal={showPatientEmailModal}
          getAppointmentData={getAppointmentData}
          handleDescriptionItem={handleDescriptionItem}
          status={status}
          connectionStatus={connectionStatus}
          timeNow={timeNow}
        />
      </Item>
    </div>
  )
}

export default AppointmentItem
