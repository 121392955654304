import React, { useState, useEffect } from 'react'
import { Row, Col, Rate, Spin } from 'antd'
import styles from '../index.module.css'

const DescriptionRatings = ({ appointment }) => {
  const [issuesPatient, setIssuesPatient] = useState(null)
  const [issuesProfessional, setIssuesProfessional] = useState(null)

  useEffect(() => {
    if (appointment && appointment.review) {
      if (appointment.review.patient && appointment.review.patient.issues)
        setIssuesPatient(appointment.review.patient.issues)
      if (
        appointment.review.professional &&
        appointment.review.professional.issues
      )
        setIssuesProfessional(appointment.review.professional.issues)
    }
  }, [appointment, setIssuesProfessional, setIssuesPatient])

  return (
    <Row className={styles.descriptionItemCard}>
      <Col span={24} className={styles.descriptionItemTitle}>
        Valoraciones:
      </Col>
      <Row>
        <Col span={24} className={styles.descriptionItemBody}>
          <Row>
            <Col span={24} style={{ padding: '10px 0px' }}>
              <p className={styles.paragraphRaitings}>
                Paciente:{' '}
                {appointment && appointment.patient && (
                  <>{`${appointment.patient.firstname} ${appointment.patient.lastname}`}</>
                )}
              </p>
            </Col>
            <Col span={24} style={{ padding: '10px 0px' }}>
              <Row>
                <Col span={24} style={{ padding: '5px 0px' }}>
                  <div>
                    {' '}
                    <Rate
                      disabled
                      defaultValue={
                        appointment &&
                        appointment.review &&
                        appointment.review.patient &&
                        appointment.review.patient.rating | 0
                      }
                    />
                  </div>
                  <p className={styles.paragraphRatingsComment}>
                    {appointment &&
                      appointment.review &&
                      appointment.review.patient &&
                      appointment.review.patient.comment}
                  </p>
                </Col>
                <Col span={24} style={{ padding: '5px 0px' }}>
                  <p className={styles.paragraphRatings}>
                    {' '}
                    Problemas mencionados
                  </p>
                  <ul className={styles.paragraphRatingsComment}>
                    {appointment.review ? (
                      issuesPatient && issuesPatient.length > 0 ? (
                        issuesPatient.map(issue => {
                          return (
                            <li style={{ margin: '0px -20px' }} key={issue}>
                              {issue}
                              <br />
                            </li>
                          )
                        })
                      ) : (
                        <span style={{ margin: '0px -40px' }}>
                          Paciente no registró problemas en la cita
                        </span>
                      )
                    ) : (
                      <span style={{ margin: '0px -40px' }}>
                        Paciente no ha evaluado la cita
                      </span>
                    )}
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.descriptionItemFooter}>
          <p className={styles.paragraphRatings}>
            Doctor:{' '}
            {!appointment.professional ? (
              <Spin />
            ) : (
              <>
                {' '}
                {`${appointment.professional.firstname} ${appointment.professional.lastname}`}{' '}
              </>
            )}
          </p>
          <ul className={styles.paragraphRatingsComment}>
            {appointment.review ? (
              issuesProfessional && issuesProfessional.length > 0 ? (
                issuesProfessional.map(issue => {
                  return (
                    <li style={{ margin: '0px -20px' }} key={issue}>
                      {issue}
                      <br />
                    </li>
                  )
                })
              ) : (
                <span style={{ margin: '0px -40px' }}>
                  Doctor no registró problemas en la cita
                </span>
              )
            ) : (
              <span style={{ margin: '0px -40px' }}>
                Doctor no ha evaluado la cita
              </span>
            )}
          </ul>
        </Col>
      </Row>
    </Row>
  )
}

export default DescriptionRatings
