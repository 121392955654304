import React from 'react'
import { Row, Col } from 'antd'
import styles from '../index.module.css'

import DescriptionGeneralInfo from './DescriptionGeneralInfo'
import DescriptionAttachmentFiles from './DescriptionAttachmentFiles'
import DescriptionConnectionState from './DescriptionConnectionState'
import DescriptionRatings from './DescriptionRatings'
import DescriptionTimeline from './DescriptionTimeline/DescriptionTimeline'

import { useGetHistories, useGetUploads } from './helpers'

const AppointmentDescriptionItem = ({ appointment }) => {
  const {
    data: uploadsData,
    loading: uploadsLoading,
    error: uploadsError,
  } = useGetUploads(appointment.room)
  const {
    data: historiesData,
    loading: historiesLoading,
    error: historiesError,
  } = useGetHistories({
    entity: {
      name: appointment.__typename.toUpperCase(),
      _id: appointment._id,
    },
  })

  return (
    <Row className={styles.descriptionItemContainer}>
      <Col xl={8} lg={8} md={24}>
        <Row style={{ width: '100%' }}>
          <Col span={24} className={styles.descriptionItemsContent}>
            <DescriptionGeneralInfo appointment={appointment} />
          </Col>
          <Col span={24} className={styles.descriptionItemsContent}>
            <DescriptionAttachmentFiles
              uploadsData={uploadsData}
              uploadsLoading={uploadsLoading}
              uploadsError={uploadsError}
            />
          </Col>
        </Row>
      </Col>
      <Col xl={8} lg={8} md={24}>
        <Row>
          <Col span={24} className={styles.descriptionItemsContent}>
            <DescriptionRatings appointment={appointment} />
          </Col>
        </Row>
      </Col>
      <Col xl={8} lg={8} md={24} className={styles.descriptionItemsContent}>
        <DescriptionTimeline
          appointment={appointment}
          historiesData={historiesData}
          historiesLoading={historiesLoading}
          historiesError={historiesError}
        />
      </Col>
      <Col xl={24} lg={24} md={24}>
        <Row>
          <Col span={24} className={styles.descriptionItemsContent}>
            <DescriptionConnectionState
              historiesData={historiesData}
              historiesLoading={historiesLoading}/>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AppointmentDescriptionItem
