import React from 'react'
import styles from './index.module.css'

const AppointmentsListNamesHeader = () => {
  return (
    <div className={styles.appointmentListHeaderContainer}>
      <div className={styles.headerListHourContainer}>
        <span>Hora</span>
      </div>
      <div className={styles.headerListStateAndDoctorContainer}>
        <span className={styles.headerListState}>Estado</span>
        <span className={styles.headerListDoctor}>
          Profesional <span>Médico</span>
        </span>
      </div>
      <div className={styles.headerListPatientAndSpeciallityContainer}>
        <span className={styles.headerListSpeciallity}>Especialidad</span>
        <span className={styles.headerListPatient}>Paciente</span>
      </div>
      <div className={styles.headerListActionContainer}>
        <span>Acciones</span>
      </div>
    </div>
  )
}

export default AppointmentsListNamesHeader
