import { useQuery } from 'react-apollo'
import { GET_HISTORIES } from 'App/Queries/AppointmentDescriptionItem'

const useGetHistories = ({ entity: { name, _id } }) => {
  const { data, loading, error, refetch } = useQuery(GET_HISTORIES, {
    variables: { entity: {
        name,
        _id
    } },
    fetchPolicy: 'network-only',
  })

  return {
    data,
    loading,
    error,
    refetch,
  }
}

export default useGetHistories