import React from 'react'
import { Modal } from 'antd'
import TableInfo from './TableInfo'

const ModalInfo = ({
  isModalVisible,
  setIsModalVisible,
  dataTable,
  fileName,
  records,
}) => {
  const fileNameArchive = fileName?.slice(0, -4)
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const titleModal = `Previsualización del archivo: ${fileNameArchive} [ ${records} ${
    records > 1 ? 'registros' : 'registro'
  } ]`

  return (
    <Modal
      footer={false}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}>
      <h2>{titleModal}</h2>
      <TableInfo dataTable={dataTable} />
    </Modal>
  )
}

export default ModalInfo
