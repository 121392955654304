import React from 'react'
import { Button } from 'antd'

import styles from '../../../index.module.css'

const SendMultipleEmail = ({showModalSendEmail, checkedList}) => {
  return (
    <Button
      type="primary"
      className={styles.buttonSendEmail}
      onClick={showModalSendEmail}
      disabled={checkedList <= 0}
      >
      Enviar Seleccionados
    </Button>
  )
}

export default SendMultipleEmail
