import React from 'react'
import { Row, Col, Timeline, Spin } from 'antd'
import styles from '../../index.module.css'
import dayjs from 'dayjs'
import { appointmentTypes } from './DescriptionTypes'
import { endsWith, startsWith } from 'ramda'

const { Item } = Timeline
// History entities list with a fill dot marker.
const isDotMarker = [
  'ATTENDED',
  'CLOSED',
  'CREATED',
  'DELETED',
  'CONFIRMED',
  'UNCONFIRMED',
]

const DescriptionTimeline = ({
  appointment,
  historiesData,
  historiesLoading,
}) => {
  const historiesDataToFilter = historiesData

  return (
    <Row className={styles.descriptionItemCard}>
      <Col span={24} className={styles.descriptionItemTitle}>
        Hitos de consulta (timeline)
      </Col>
      <Col span={24} className={styles.descriptionItemBody}>
        {historiesLoading ? (
          <Spin>Cargando información...</Spin>
        ) : (
          <Timeline>
            {historiesDataToFilter &&
              historiesDataToFilter.allHistories.filter(item => {
                return !(endsWith('_DISCONNECTED', item.event) || endsWith('_CONNECTED', item.event))
              }).map(history => {
              return (
                <Item
                  dot={
                    isDotMarker.includes(history.event) ? (
                      <div className={styles.dotTimeline} />
                    ) : (
                      ''
                    )
                  }
                  color="#6B7CFF"
                  key={history._id}>
                  <Row>
                    <Col span={12}>
                      <p className={styles.paragraphTimelineTitle}>
                        {appointmentTypes[history.event].description}
                      </p>
                      <p className={styles.paragraphTimelineDate}>
                        {dayjs(history.createdAt).format(`D [de] MMMM [a las] H:mm [hrs.]`)}
                      </p>
                    </Col>
                    <Col span={12}>
                      <p className={styles.paragraphTimelineText}>
                        {' '}
                        {appointment &&
                          appointment.admin &&
                          appointment.admin.firstname &&
                          appointment.admin.lastname && history.event === 'CREATED' &&
                          `${appointment.admin.firstname} ${appointment.admin.lastname}`}
                        {startsWith('PATIENT_', history.event) &&
                          !startsWith('PATIENT_NOTIFIED_', history.event) &&
                          ' Participante Paciente'}
                        {startsWith('PATIENT_NOTIFIED_', history.event) ?
                          history.meta &&
                          history.meta.length > 1 ?
                          history.meta.find(elem => elem.name === 'email') &&
                          history.meta.find(elem => elem.name === 'email').value : ' Participante Paciente'
                        : ''}
                        {startsWith('PROFESSIONAL_', history.event) && ' Participante Doctor'}
                      </p>
                    </Col>
                  </Row>
                </Item>
              )
            })}
          </Timeline>
        )}
      </Col>
    </Row>
  )
}

export default DescriptionTimeline
