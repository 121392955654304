/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react'
import { Select } from 'antd'
import useStateWithLocalStorage from 'App/Hooks/useStateWithLocalStorage'

const { Option } = Select

const DepartmentsSelect = ({
  disabled,
  selectedProfessionals,
  setSelectedDepartments,
  setDisabled,
  fetchDepartments,
  setDepartmentsIds,
  departmentsIds,
  professionalsIds
}) => {
  let tempDepartmentsIds = []
  const [departmentsList, setDepartmentsList] = useState([])
  const [departmentsNames, setDepartmentsNames] = useStateWithLocalStorage('departmentsNames')
  const longitudeDeps = departmentsIds.length > 0 ? departmentsIds.toString().split(',').length : 0
  const longitudeProf = professionalsIds.length > 0 ? professionalsIds.toString().split(',').length : 0


  const handleChangeDepartments = value => {
    setSelectedDepartments([
      ...value
    ])
  }

  const handleSearchDepartments = useCallback(
    async filter => {
      setDepartmentsList([])
      const data = await fetchDepartments(filter)
      setDepartmentsList(data)
    },
    [fetchDepartments],
  )

  useEffect(() => {
    handleSearchDepartments('')
  }, [handleSearchDepartments])

  const handleChange = (...args) => {
    tempDepartmentsIds = []
    args[1].map(i => (
      tempDepartmentsIds.push(i.id)
    ))
    setDepartmentsNames(args[0])
    setDepartmentsIds(tempDepartmentsIds)
    handleChangeDepartments(tempDepartmentsIds)
  }

  useEffect(() => {
    if (longitudeProf > 0 && longitudeDeps === 0)
      setDisabled(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeselect = () => {
    if ((tempDepartmentsIds.length === 0 || longitudeDeps === 0) && (selectedProfessionals.length > 0 || longitudeProf > 0))
      setDisabled(true)
  }

  return (
    <Select
      autoClearSearchValue
      mode="multiple"
      disabled={disabled}
      defaultValue={departmentsNames.length > 1 ? departmentsNames.toString().split(',') : departmentsNames}
      style={{ width: '100%', padding: '5px' }}
      placeholder={disabled ? 'Deseleccione los profesionales' : 'Seleccione Especialidades'}
      onChange={handleChange}
      onDeselect={handleDeselect}
    >
      {departmentsList.map(i => (
        <Option key={i.value} id={i.id}>{i.label}</Option>
      ))}
    </Select>

  )
}


export default DepartmentsSelect