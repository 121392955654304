import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    dataIndex: 'index',
    key: 'index',
    width: 60,
    render: (value, item, index) => index + 1,
  },
  {
    title: 'FPAGO',
    dataIndex: 'FPAGO',
    key: 'FPAGO',
    width: 150,
  },
  {
    title: 'EPISODIO',
    dataIndex: 'EPISODIO',
    key: 'EPISODIO',
    width: 150,
  },
  {
    title: 'CITA',
    dataIndex: 'CITA',
    key: 'CITA',
    width: 150,
  },
  {
    title: 'URL',
    key: 'URL',
    dataIndex: 'URL',
    render: text => (
      <a href={text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
  },
]

const TableInfo = ({ dataTable }) => {
  return (
    <Table
      columns={columns}
      dataSource={dataTable}
      pagination={false}
      scroll={{ y: 420, x: '85vw' }}
    />
  )
}

export default TableInfo
