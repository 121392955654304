import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import dayjs from 'dayjs'

dayjs.locale('es')
Sentry.init({
  dsn:
    'https://388d0de231e943a7b656399ef553f17d@o342603.ingest.sentry.io/5178108',
  environment: process.env.REACT_APP_BRANCH,
  release: `clc-app-secretary@${process.env.REACT_APP_COMMIT_REF}`,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
