import React, { useContext, useState, useEffect } from 'react'
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import UserMenu from './UserMenu'
import { UserContext } from 'App/contexts/UserContext'

const propTypes = {}

const defaultProps = {}

const UserDropDown = () => {
  const [username, setUsername] = useState('')
  const { user } = useContext(UserContext)

  useEffect(() => {
    const name = `${user.firstname} ${user.lastname}`
    setUsername(name)
  }, [user])

  return (
    <Dropdown overlay={<UserMenu />}>
      <span
        style={{
          color: 'rgba(89,116,175)',
          fontWeight: 'bold',
          marginRight: '20px',
          cursor: 'pointer',
        }}>
        {username} <DownOutlined />
      </span>
    </Dropdown>
  )
}

UserDropDown.propTypes = propTypes
UserDropDown.defaultProps = defaultProps

export default UserDropDown
