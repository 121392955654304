import React, { useState, Fragment } from 'react'
import { useQuery } from '@apollo/react-hooks'
import AppointmentList from './AppointmentsList'
import AppointmentMenu from './AppointmentMenu'
import useStateWithLocalStorage from 'App/Hooks/useStateWithLocalStorage'
import get from 'lodash/get'
import { GET_DEPARTMENTS, GET_PROFESSIONALS } from 'App/Queries'

const Appointments = () => {
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [selectedProfessionals, setSelectedProfessionals] = useState([])
  const [datePicker, setDatePicker] = useState(null)
  const [showAppointmentDescription, setShowAppointmentDescription] = useState(
    false,
  )
  const [departmentsIds, setDepartmentsIds] = useStateWithLocalStorage(
    'departmentsIds',
  )
  const [professionalsIds, setProfessionalsIds] = useStateWithLocalStorage(
    'professionalsIds',
  )
  const getDepartments = useQuery(GET_DEPARTMENTS)
  const getProfessionals = useQuery(GET_PROFESSIONALS, {
    variables: {
      departments:
        departmentsIds.length > 0 ? departmentsIds.toString().split(',') : [],
    },
  })
  const allDepartments = get(getDepartments.data, 'allDepartments', [])
  const allProfessionals = get(getProfessionals.data, 'allProfessionals', [])

  const fetchDepartments = async filter => {
    const departments = allDepartments.map(i => ({
      id: i._id,
      value: i.name,
      label: i.name,
    }))
    return departments.filter(i => i.label.includes(filter))
  }

  const fetchProfessionals = async filter => {
    const professionals = allProfessionals.map(i => {
      return {
        id: i._id,
        value: `${i.firstname} ${i.lastname}`,
        label: `${i.firstname} ${i.lastname}`,
        departments: i.departments.map(j => j._id),
      }
    })
    return professionals.filter(i => i.label.includes(filter))
  }

  return (
    <Fragment>
      <AppointmentMenu
        fetchDepartments={fetchDepartments}
        fetchProfessionals={fetchProfessionals}
        setSelectedDepartments={setSelectedDepartments}
        setSelectedProfessionals={setSelectedProfessionals}
        selectedDepartments={selectedDepartments}
        selectedProfessionals={selectedProfessionals}
        setDepartmentsIds={setDepartmentsIds}
        setProfessionalsIds={setProfessionalsIds}
        professionalsIds={professionalsIds}
        departmentsIds={departmentsIds}
        setDatePicker={setDatePicker}
        showAppointmentDescription={showAppointmentDescription}
        setShowAppointmentDescription={setShowAppointmentDescription}
      />
      <AppointmentList
        professionalsIds={professionalsIds}
        departmentsIds={departmentsIds}
        datePicker={datePicker}
        showAppointmentDescription={showAppointmentDescription}
        setShowAppointmentDescription={setShowAppointmentDescription}
      />
    </Fragment>
  )
}

export default Appointments
