import React, { useState, useEffect } from 'react'
import { Row, Col, Checkbox } from 'antd'
import styles from './index.module.css'

const LabelColorAndNumberCard = ({ label, color, value }) => {
  return (
    <div className={styles.headerCardContent}>
      <div className={styles.headerCardDot} style={{ background: color }} />
      {label}
      <span className={styles.headerCardValue}>{value}</span>
    </div>
  )
}

const AppointmentsSubHeader = ({
  appointments,
  compressedMode,
  setCompressedMode,
}) => {
  const [endAppointments, setEndAppointments] = useState(0)
  const [pendingAppointments, setPendingAppointments] = useState(0)

  useEffect(() => {
    if (appointments.length) {
      setEndAppointments(
        appointments.filter(
          appointment =>
            appointment.appointment &&
            appointment.appointment.closedAt !== null,
        ).length,
      )
      setPendingAppointments(
        appointments.filter(
          appointment =>
            appointment.appointment &&
            appointment.appointment.closedAt === null,
        ).length,
      )
    }
  }, [appointments])

  return (
    <div className={styles.listHeaderInfoContainer}>
      <Row justify="middle" style={{ width: '100%' }}>
        <Col span={10}>
          <div className={styles.titleSubHeader}>CONSULTAS</div>
        </Col>
        <Col span={14}>
          <Row justify="middle">
            <Col xl={8} sm={24} style={{ display: 'flex' }}>
              <Checkbox
                className={styles.checkBoxSubHeader}
                checked={compressedMode}
                onChange={e => {
                  setCompressedMode(e.target.checked)
                }}
                disabled={pendingAppointments === 0 && endAppointments === 0}>
                Activar modo comprimido.
              </Checkbox>
            </Col>
            <Col xl={16} sm={4}>
              <div className={styles.headerCardContainer}>
                <LabelColorAndNumberCard
                  label="Finalizadas"
                  color="#5974AF"
                  value={endAppointments}
                />
                <LabelColorAndNumberCard
                  label="Paciente Conectado"
                  color="#5CC531"
                />
                <LabelColorAndNumberCard
                  label="Pendientes"
                  color="#9F9F9F"
                  value={pendingAppointments}
                />
                <LabelColorAndNumberCard
                  label="En Consulta"
                  color="rgb(255, 43, 81)"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default AppointmentsSubHeader
