import { gql } from 'apollo-boost'

const CONFIRM_APPOINTMENT = gql`
  mutation($id: ID!) {
    confirmAppointment(_id: $id) {
      _id
      confirmedAt
    }
  }
`
const UNCONFIRM_APPOINTMENT = gql`
  mutation($id: ID!) {
    unconfirmAppointment(_id: $id) {
      _id
      confirmedAt
    }
  }
`

export { CONFIRM_APPOINTMENT, UNCONFIRM_APPOINTMENT }
