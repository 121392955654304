import React from 'react'

const Departments = ({
  styles,
  appointment,
  handleDescriptionItem,
  keyProp,
}) => {
  return (
    <div
      className={styles.bodyListPatientAndSpeciallityContainer}
      onClick={() => {
        if (appointment) handleDescriptionItem(appointment)
      }}
      key={`2${keyProp}`}>
      <span className={styles.bodyListSpeciallity}>
        {appointment &&
        appointment.professional &&
        appointment.professional.departments &&
        appointment.professional.departments.length > 0 ? (
          appointment.professional.departments.map(
            (department, keyDepartment) => {
              return (
                <div key={`dep-${keyDepartment}`}>
                  <span>{department.name}</span>
                  <span className={styles.addCommaSeparator}>{', '}</span>
                </div>
              )
            },
          )
        ) : (
          <span>{'SIN ESPECIALIDADES'}</span>
        )}
      </span>
      <span className={styles.bodyListPatient}>
        {`${appointment.patient.firstname} ${appointment.patient.lastname}`}
      </span>
    </div>
  )
}

export default Departments
