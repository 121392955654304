import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import AppLayout from './AppLayout'
import Appointments from './Appointments'
import { LoginScreen } from '@clinicalascondes/clc-parts'
import UploadInvoice from './UploadInvoices'
import Invoices from './Invoices'

const propTypes = {}

const defaultProps = {}

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}

const Screens = ({ children }) => {
  return (
    <Switch>
      <Route
        path="/login"
        component={props => {
          return (
            <LoginScreen label="Secretaría" path="appointments" {...props} />
          )
        }}
      />
      <AppLayoutRoute path="/appointments" component={Appointments} />
      <AppLayoutRoute path="/invoices" component={Invoices} />
      <AppLayoutRoute path="/uploadinvoices" component={UploadInvoice} />
      <AppLayoutRoute
        path="/"
        component={() => <Redirect to={'/appointments'} />}
      />
      <Route component={() => <h1>404</h1>} />
    </Switch>
  )
}

Screens.propTypes = propTypes
Screens.defaultProps = defaultProps

export default Screens
