import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'

import { Auth } from 'aws-amplify'
import Header from './Header'

const { Content } = Layout

const AppLayout = ({ history, children }) => {
  useEffect(() => {
    Auth.currentSession()
      .then(() => {})
      .catch(err => {
        console.log(err)
        return history.push('/login')
      })
  }, [history])

  return (
    <Layout style={{ backgroundColor: '#FFF' }}>
      <Row justify="space-around" align="middle">
        <Col
          md={24}
          style={{
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            position: 'relative',
          }}>
          <div style={{ height: '10vh' }}>
            <Header />
          </div>
          <Layout style={{ height: '90vh' }}>
            <Content style={{ background: 'white' }}>{children}</Content>
          </Layout>
        </Col>
      </Row>
    </Layout>
  )
}

export default withRouter(AppLayout)
