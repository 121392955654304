import React, { useState, useEffect } from 'react'
import { Modal, Input, Form, Button, Row, Col, notification, Spin } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { SEND_EMAIL_INVOICE } from '../Graphql/Mutators/EmailMutators'
import { Client2 as InvoiceClient } from '../../../ApolloClient/index'
import styles from './index.module.css'

const EmailModal = ({ isOpen, invoice, showEmailModal, handleRefresh }) => {
  const [sendEmailInvoiceMutation] = useMutation(SEND_EMAIL_INVOICE, { client: InvoiceClient })
  const [newEmail, setNewEmail] = useState(null)
  const [validateStatus, setValidateStatus] = useState('success')
  const [loadingResend, setLoadingResend] = useState(false)
  // eslint-disable-next-line
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  useEffect(() => {
    if (invoice) setNewEmail(invoice.patientEmail)
  }, [invoice])

  const handleEmail = e => {
    const email = e.target.value
    setNewEmail(email)
    if (re.test(email)) setValidateStatus('success')
    else setValidateStatus('error')
  }

  const sendEmailInvoice = async () => {
    Modal.confirm({
      title: '¿Está seguro de continuar con el envío del correo electrónico?',
      okText: 'Continuar',
      autoFocusButton: 'cancel',
      cancelText: 'Cancelar',
      async onOk() {
        setLoadingResend(true)
        try {
          const response = await sendEmailInvoiceMutation({ variables: { invoiceId: invoice.invoiceId, email: newEmail } })
          if (!response.data.sendSingleInvoiceEmail.success)
            notification.error({
              message:
                'Hubo un problema enviando el email, por favor revise el correo del paciente e inténtelo de nuevo.',
            })
           else
            notification.success({
              message: 'Email enviado',
            })
          setLoadingResend(false)
        } catch (err) {
          notification.error({
            message:
              'Hubo un problema enviando el email, por favor revise el correo del paciente e inténtelo de nuevo.',
          })
          console.log(err)
          setLoadingResend(false)
        }
        handleRefresh()
      }
    })
  }
  return (
    isOpen && (
      <Modal centered
      title='Envío de Boleta'
      visible={isOpen}
      onOk={sendEmailInvoice}
      onCancel={() => {
        setNewEmail(invoice.patientEmail)
        showEmailModal()
      }}
      footer={[
        <Button
          className={styles.cancelButton}
          key='back'
          autoFocus
          onClick={() => {
            setNewEmail(invoice.patientEmail)
            showEmailModal()
          }}>
          Cancelar
        </Button>,
        <Button
          className={styles.saveButton}
          key='submit'
          type='primary'
          disabled={validateStatus !== 'success'}
          onClick={sendEmailInvoice}
        >
          { loadingResend ? <Spin /> : invoice.invoiceState === 'Enviado' ? 'Reenviar' : 'Enviar' }
        </Button>,
      ]}>
        <h3 className={styles.headerPatientName}>
          { invoice.patientName.toUpperCase() }
        </h3>
        <Row gutter={[8, 8]}>
          <Col span={6} className={styles.textField}>
            Rut
          </Col>
          <Col span={18} >
            { invoice.patientRut }
          </Col>

          <Col span={6} className={styles.textField}>
            Fecha
          </Col>
          <Col span={18} >
            { invoice.appointmentDate }
          </Col>

          <Col span={6} className={styles.textField}>
            Profesional
          </Col>
          <Col span={18} >
            { invoice.professionalName }
          </Col>

          <Col span={24} style={{ paddingTop: '20px' }}>
            <Form>
              <Form.Item hasFeedback validateStatus={validateStatus} style={{ marginBottom: '0' }}>
                <Input
                  type="email"
                  style={{ width: '100%' }}
                  value={newEmail}
                  onChange={handleEmail}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    )
  )
}

export default EmailModal
