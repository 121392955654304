import React, { useEffect, useState } from 'react'
import { DatePicker, Col, List, Button, Checkbox, notification } from 'antd'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import SendEmail from './InvoicePopUps/SendEmails'
import { Client2 } from 'App/ApolloClient'
import InvoiceItem from './InvoiceItem'
import { GET_INVOICES } from './Graphql'
import InvoicesListNamesHeader from './InvoicesListNamesHeader'

import styles from './index.module.css'
import EmailModal from './InvoicePopUps/EmailModal'
import SendMultipleEmail from './InvoiceItem/Components/SendMultipleEmail'

const CheckboxGroup = Checkbox.Group
const { RangePicker } = DatePicker

const Invoices = () => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'days').endOf('day'),
  )
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'days').endOf('day'),
  )
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [loadInvoices, setLoadInvoices] = useState(true)
  const [sent, setSent] = useState(false)
  const [notSent, setNotSent] = useState(true)
  const [isOpenEmail, setIsOpenEmail] = useState(false)
  const [invoiceData, setInvoiceData] = useState(null)
  const [modalSendEmails, setModalSendEmails] = useState(false)
  const [message, setMessagge] = useState('')
  const [warning, setWarning] = useState('')

  const [getInvoices, { data, loading }] = useLazyQuery(GET_INVOICES, {
    variables: {
      startDate: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
      sent: false,
      notSent: true,
    },
    client: Client2,
    fetchPolicy: 'network-only',
  })
  const handleCalendarChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }

  const handleClick = () => {
    const yesterday = moment().subtract(1, 'days').endOf('day')
    let errorMessage
    if (!endDate || !startDate) {
      errorMessage =
        'Los campos fecha de inicio y término deben estar completados ' +
        'al momento de la búsqueda'
    } else if (endDate.isAfter(yesterday)) {
      errorMessage = 'La fecha de término no puede ser posterior a hoy'
    } else if (startDate.isAfter(endDate)) {
      errorMessage =
        // eslint-disable-next-line
        'La fecha de inicio no puede ser posterior a la' + ' fecha de término'
    } else if (
      endDate.diff(startDate, 'months') > 2 ||
      startDate.diff(endDate, 'months') > 2
    ) {
      errorMessage = 'El rango de fechas no debe ser mayor a 3 meses'
    }

    if (errorMessage) {
      notification.error({
        message: errorMessage,
      })
      return
    }

    handleSearchEvent()
  }

  const disabledDate = current => {
    const yesterday = moment().subtract(1, 'days').endOf('day')
    const beforeToday = current && current.isAfter(yesterday)
    const futureThreeMonths = startDate && current.diff(startDate, 'months') > 2
    const pastThreeMonths = endDate && current.diff(endDate, 'months') > 2
    return beforeToday || futureThreeMonths || pastThreeMonths
  }

  const onCheckAllChange = e => {
    const invoicesId = data?.getInvoices.map(i => i.invoiceId)
    setCheckedList(e.target.checked ? invoicesId : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const onChangeBox = item => {
    setCheckedList(item)
    setIndeterminate(!!item.length && item.length < data?.getInvoices.length)
    setCheckAll(item.length === data?.getInvoices.length)
  }

  const onChangeSentFilter = event => {
    setSent(event.target.checked)
  }

  const onChangeNotSentFilter = event => {
    setNotSent(event.target.checked)
  }

  const showInvoiceEmailModal = () => {
    setIsOpenEmail(!isOpenEmail)
  }

  const setInvoiceItemProps = invoice => {
    showInvoiceEmailModal()
    setInvoiceData(invoice)
  }

  const handleRefresh = () => {
    setIsOpenEmail(!isOpenEmail)
    handleSearchEvent()
  }
  const handleSearchEvent = () => {
    cleanCheckBox()
    getInvoices({
      variables: {
        startDate: startDate?.format('YYYY-MM-DD'),
        endDate: endDate?.format('YYYY-MM-DD'),
        sent: sent,
        notSent: notSent,
      },
    })
  }
  const cleanCheckBox = () => {
    setCheckedList([])
    setCheckAll(false)
    setIndeterminate(false)
  }

  useEffect(() => {
    if (loadInvoices) {
      getInvoices()
      setLoadInvoices(false)
      cleanCheckBox()
    }
  }, [loadInvoices, getInvoices])

  const putMessage = () => {
    let invoicesFilter = data?.getInvoices?.filter(
      i => i.invoiceState === 'Enviado',
    )
    let invoicesFilterId = invoicesFilter?.map(i => i.invoiceId)
    let reSent = false
    let counter = 0
    for (let i = 0; i < checkedList.length; i++) {
      reSent = invoicesFilterId?.includes(checkedList[i])
      if(reSent) counter++
      if (reSent && counter>1) {
        setWarning(
          'Ha seleccionado pacientes que ya se les ha enviado la boleta, ',
        )
        setMessagge(
          `${
            checkedList.length > 1
              ? `¿Desea enviar las ${checkedList?.length} boletas seleccionadas?`
              : '¿Desea enviar la boleta?'
          }`,
        )
        break
      }
    }
    if(counter===1){
      setWarning(
        'Ha seleccionado un paciente que ya se ha enviado la boleta, ',
      )
      setMessagge(
        `${
          checkedList.length > 1
            ? `¿Desea enviar las ${checkedList?.length} boletas seleccionadas?`
            : '¿Desea enviar la boleta?'
        }`,
      )
    }
    if (message.length === 0 && !reSent) {
      if (checkedList.length === 1)
        setMessagge(`¿Desea enviar el correo seleccionado?`)
      else
        setMessagge(
          `¿Desea enviar ${checkedList?.length} correos seleccionados?`,
        )
    }
  }

  const showModalSendEmail = () => {
    putMessage()
    setModalSendEmails(true)
  }

  return (
    <>
      <Col span={25}>
        <div className={styles.listHeaderGroupedInfoContainer}>
          <div className={styles.titleSubHeader}>
            Seleccionar rango de fechas
          </div>
          <div className={styles.filterContainer}>
            <RangePicker
              disabledDate={disabledDate}
              onCalendarChange={handleCalendarChange}
              placeholder={['Fecha Inicio', 'Fecha Final']}
              defaultPickerValue={[moment().subtract(1, 'months'), null]}
              format="DD-MM-YYYY"
              defaultValue={[startDate, endDate]}
            />
            <Checkbox
              onChange={onChangeSentFilter}
              defaultChecked={sent}
              className={styles.checkboxSent}>
              Enviados
            </Checkbox>
            <Checkbox onChange={onChangeNotSentFilter} defaultChecked={notSent}>
              Fallidos
            </Checkbox>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="primary"
              className={styles.invoiceButton}
              loading={loading}
              onClick={handleClick}>
              Buscar
            </Button>
          </div>
        </div>
      </Col>
      <Col span={23}>
        <div className={styles.buttonSendContainer}>
          <SendMultipleEmail
            showModalSendEmail={showModalSendEmail}
            checkedList={checkedList.length}
          />
          <SendEmail
            warning={warning}
            setWarning={setWarning}
            message={message}
            setMessagge={setMessagge}
            modalSendEmails={modalSendEmails}
            setModalSendEmails={setModalSendEmails}
            checkedList={checkedList}
            handleSearchEvent={handleSearchEvent}
          />
        </div>
      </Col>
      <Col span={23}>
        <div className={styles.appointmentListContainer}>
          <InvoicesListNamesHeader
            indeterminate={indeterminate}
            onCheckAllChange={onCheckAllChange}
            checkAll={checkAll}
            invoices={data?.getInvoices}
          />
          <CheckboxGroup
            className={styles.appointmentList}
            onChange={onChangeBox}
            value={checkedList}>
            <List
              size={'large'}
              loading={loading}
              dataSource={data?.getInvoices}
              bordered
              renderItem={(item, key) => (
                <>
                  <InvoiceItem
                    {...item}
                    keyProp={key}
                    item={item}
                    handleFunction={setInvoiceItemProps}
                  />
                </>
              )}
            />
          </CheckboxGroup>
        </div>
      </Col>
      <EmailModal
        isOpen={isOpenEmail}
        invoice={invoiceData}
        showEmailModal={showInvoiceEmailModal}
        handleRefresh={handleRefresh}
      />
    </>
  )
}

export default Invoices
