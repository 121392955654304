import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Progress } from 'antd'
import {
  openErrorNotification,
  openSuccessNotification,
} from '../../Components/NotificationUpload'
import UploadButton from './UploadButton'
import ModalInfo from './ModalInfo'
import Papa from 'papaparse'

const UploadInvoice = () => {
  const [fileName, setFileName] = useState('')
  const [fileData, setFileData] = useState('')
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [progressUpload, setProgressUpload] = useState('')
  const [dataCsv, setDataCsv] = useState('')
  const [dataTable, setDataTable] = useState('')
  const [records, setRecords] = useState('')

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleClick = () => {
    document.getElementById('csv-link').click()
  }

  useEffect(() => {
    Papa.parse(dataCsv, {
      header: true,
      complete: result => {
        setRecords(result.data.length)
        setDataTable(result?.data)
      },
    })
  }, [dataCsv])

  const handleFileChange = e => {
    const nameValidator = /^([a-z]{4})(_)(2[0-9]{3})(\/|)(0[1-9]|1[0-2])([0-2][0-9]|3[0-1])(\.)([a-z]{3})$/
    const file = e.target.files[0]
    const reader = new FileReader()

    file && reader.readAsText(file)

    reader.onload = () => {
      setDataCsv(reader?.result)
    }

    if (nameValidator.exec(file?.name)) {
      setFileData(file)
      setLoading(true)
      setTimeout(() => {
        setFileName(file?.name)
        setLoading(false)
      }, 2000)
    } else {
      setFileName(null)
      setFileData(null)
      setProgressUpload(null)
      setLoading(false)
      openErrorNotification(
        '!Error!',
        'Error en el nombre del archivo, intente nuevamente.',
      )
    }
  }

  const fileUploadHandler = async () => {
    let formData = new FormData()
    formData.append('file', fileData)

    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        setTimeout(() => {
          setProgressUpload(percent)
        }, 300)
        setProgressUpload(50)
      },
    }

    await axios
      .post(process.env.REACT_APP_API_INVOICES_UPLOAD, formData, options)
      .then(() => {
        setTimeout(() => {
          document.getElementById('csv-link').value = ''
          setFileName(null)
          setFileData(null)
          setProgressUpload(null)
          openSuccessNotification('!Cargado!', 'Archivo cargado correctamente.')
        }, 1000)
      })
      .catch(() => {
        openErrorNotification(
          '!Error!',
          'Error al cargar su archivo, intente nuevamente.',
        )
      })
  }

  const nameButtonUpload = () =>
    progressUpload ? (
      <Progress percent={progressUpload} />
    ) : fileName ? (
      fileName
    ) : (
      'Adjuntar archivo carga masiva'
    )

  useEffect(() => {
    fileName ? setDisable(false) : setDisable(true)
  }, [fileName])

  return (
    <>
      <UploadButton
        handleFileChange={handleFileChange}
        loading={loading}
        handleClick={handleClick}
        nameButtonUpload={nameButtonUpload}
        showModal={showModal}
        disable={disable}
        fileUploadHandler={fileUploadHandler}
      />
      <ModalInfo
        records={records}
        fileName={fileName}
        dataTable={dataTable}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  )
}

export default UploadInvoice
