import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import styles from './../styles.module.css'

const UploadButton = ({
  handleFileChange,
  loading,
  handleClick,
  nameButtonUpload,
  showModal,
  disable,
  fileUploadHandler,
}) => {
  return (
    <div className={styles.containerButton}>
      <input
        type="file"
        name="file"
        accept=".csv"
        hidden
        id="csv-link"
        onChange={handleFileChange}
      />
      <Button
        loading={loading}
        icon={<UploadOutlined />}
        onClick={handleClick}
        className={styles.uploadButton}>
        {nameButtonUpload()}
      </Button>
      <Button
        type="link"
        disabled={disable}
        onClick={showModal}
        className={styles.infoButton}>
        Previsualizar
      </Button>
      <Button
        type="primary"
        disabled={disable}
        className={styles.sendButton}
        onClick={fileUploadHandler}>
        Enviar
      </Button>
      <div className={styles.description}>
        <p>
          El nombre de este archivo debe estar escrito como por ejemplo:
          "caja_AAAAMMDD".
        </p>
        <p>Para eliminar el archivo, ingresa al drop y presione "Cancelar"</p>
      </div>
    </div>
  )
}

export default UploadButton

UploadButton.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  nameButtonUpload: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  fileUploadHandler: PropTypes.func.isRequired,
}
