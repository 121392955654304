import ApolloClient from 'apollo-boost'
import customFetch from './customFetch'

const Client = new ApolloClient({
  fetch: customFetch,
  uri: process.env.REACT_APP_API_ENDPOINT,
})

const Client2 = new ApolloClient({
  fetch: customFetch,
  uri: process.env.REACT_APP_API_INVOICES_GQL,
  fetchOptions: {
    mode: 'cors',
  },
})

export { Client, Client2 }
