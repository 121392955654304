import React from 'react'
import Root from './Root'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Screens from './Screens'
import 'typeface-roboto'
import 'typeface-inter'

import { ApolloApp, AblyProvider, IntercomApp } from './Providers'
import { UserProvider } from 'App/contexts/UserContext'
import { Client } from './ApolloClient'

const propTypes = {}

const defaultProps = {}

const App = () => {
  return (
    <ApolloApp client={Client}>
      <Router>
        <UserProvider>
          <AblyProvider>
            <IntercomApp>
              <Route component={Root}>{Screens}</Route>
            </IntercomApp>
          </AblyProvider>
        </UserProvider>
      </Router>
    </ApolloApp>
  )
}

App.propTypes = propTypes
App.defaultProps = defaultProps

export default App
