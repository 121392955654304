import React from 'react'

import { Button, Tooltip } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const ResendEmail = ({
  appointment,
  getAppointmentData,
  showPatientEmailModal,
}) => {
  return (
    <Tooltip title="Reenviar Email" color={'#2D3F8C'}>
      <Button
        shape="circle"
        icon={<MailOutlined />}
        onClick={() => {
          getAppointmentData(appointment)
          showPatientEmailModal()
        }}
      />
    </Tooltip>
  )
}

export default ResendEmail
