import { gql } from 'apollo-boost'

const RESEND_APPOINTMENT = gql`
  mutation resendAppointment($_id: ID!) {
    resendAppointment(_id: $_id)
  }
`
const UPDATE_PATIENT = gql`
  mutation updatePatient($_id: ID!, $input: PatientUpdateInput) {
    updatePatient(_id: $_id, input: $input) {
      _id
      firstname
      lastname
      rut
      email
      birthdate
      phone
    }
  }
`

export { RESEND_APPOINTMENT, UPDATE_PATIENT }
