import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { List, Row, Col } from 'antd'
import AppointmentItem from './ApointmentItem'
import useAppointments from './useAppointments'
import AlertModal from './ApointmentPopUps/AlertModal'
import EmailModal from './ApointmentPopUps/EmailModal'
import AppointmentsSubHeader from './AppointmentsSubHeader'
import AppointmentsListNamesHeader from './AppointmentsListNamesHeader'
import AppointmentDescriptionItem from './AppointmenDescriptionItem'
import moment from 'moment'
import styles from './index.module.css'
import { AblyContext } from 'App/Providers'

const propTypes = {
  professional: PropTypes.string,
}

const defaultProps = {}

const AppointmentList = ({
  professionalsIds,
  departmentsIds,
  datePicker,
  showAppointmentDescription,
  setShowAppointmentDescription,
}) => {
  const { appointments, loading } = useAppointments(
    64,
    professionalsIds.length > 0 ? professionalsIds.toString().split(',') : [],
    departmentsIds.length > 0 ? departmentsIds.toString().split(',') : [],
    datePicker,
  )
  const [compressedMode, setCompressedMode] = useState(false)
  const { realtime } = useContext(AblyContext)
  const [waitingRoomUsers, setWaitingRoomUsers] = useState([])
  const [appointUsers, setAppointUsers] = useState([])
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [isOpenEmail, setIsOpenEmail] = useState(false)
  const [appointmentData, setAppointmentData] = useState(null)
  const [appointmentsAllData, setAppointmentsAllData] = useState([])
  const [
    appointmentDataToDescriptionView,
    setAppointmentDataToDescriptionView,
  ] = useState(null)
  const [timeNow, setTimeNow] = useState(0)

  useEffect(() => {
    setTimeNow(parseInt(moment().format('HH'), 10))
    setTimeout(() => {
      window.location.reload()
    }, 1000 * 60 * 180)
  }, [])

  useEffect(() => {
    if (appointments.length > 0 && !loading)
      setAppointmentsAllData(appointments)
  }, [appointments, loading])

  useEffect(() => {
    if (appointments.length > 0)
      if (compressedMode) {
        const app = []
        appointments.find(item => {
          if (item.appointment) app.push(item)
          // eslint-disable-next-line array-callback-return
          return
        })
        setAppointmentsAllData(app)
      } else setAppointmentsAllData(appointments)
  }, [appointments, compressedMode])

  useEffect(() => {
    setCompressedMode(false)
  }, [datePicker])

  useEffect(() => {
    if (!realtime) return
    const waitingChannel = realtime.channels.get('wait-room')
    const appointChannel = realtime.channels.get('appointment')
    waitingChannel.presence.get((err, presenceSet) => {
      if (err) return console.error('Could not get presence channel')
      setWaitingRoomUsers(presenceSet)
      waitingChannel.presence.subscribe(() => {
        waitingChannel.presence.get((err, presenceSet) => {
          if (err) return console.error('Could not get users')
          setWaitingRoomUsers(presenceSet)
        })
      })
    })
    appointChannel.presence.get((err, presenceSet) => {
      if (err) return console.error('Could not get presence channel')
      setAppointUsers(presenceSet)
      appointChannel.presence.subscribe(() => {
        appointChannel.presence.get((err, presenceSet) => {
          if (err) return console.error('Could not get users')
          setAppointUsers(presenceSet)
        })
      })
    })
  }, [realtime])

  const roundedDown = Math.floor(moment().minute() / 15) * 15

  const showPatientAlertModal = () => {
    setIsOpenAlert(!isOpenAlert)
  }

  const showPatientEmailModal = () => {
    setIsOpenEmail(!isOpenEmail)
  }

  const handleDescriptionItem = appointment => {
    setAppointmentDataToDescriptionView(appointment)
    setTimeout(() => {
      setShowAppointmentDescription(true)
    }, 200)
  }
  return (
    <Row
      className={styles.centerContent}
      style={{
        overflow: showAppointmentDescription && 'auto',
        height: showAppointmentDescription && '80%',
      }}>
      {!showAppointmentDescription ? (
        <>
          <Col span={24}>
            <AppointmentsSubHeader
              appointments={appointmentsAllData}
              compressedMode={compressedMode}
              setCompressedMode={setCompressedMode}
            />
          </Col>
          <Col span={24}>
            <div className={styles.appointmentListContainer}>
              <AppointmentsListNamesHeader />
              <div className={styles.appointmentList}>
                <List
                  size={'large'}
                  loading={loading}
                  dataSource={appointmentsAllData}
                  bordered
                  renderItem={(item, key) => (
                    <>
                      <AppointmentItem
                        {...item}
                        waitingRoomUsers={waitingRoomUsers}
                        appointUsers={appointUsers}
                        showPatientAlertModal={showPatientAlertModal}
                        showPatientEmailModal={showPatientEmailModal}
                        getAppointmentData={setAppointmentData}
                        handleDescriptionItem={handleDescriptionItem}
                        keyProp={key}
                        timeNow={timeNow}
                      />
                      {item.time ===
                      moment()
                        .minute(roundedDown)
                        .second(0)
                        .millisecond(0)
                        .format('HH:mm') ? (
                        <div
                          style={{
                            // height: '0.3vh',
                            backgroundColor: 'rgba(89,116,175, 0.95)',
                            width: '110%',
                            marginLeft: '-5%',
                          }}
                        />
                      ) : null}
                    </>
                  )}
                />
              </div>
              <AlertModal
                isOpen={isOpenAlert}
                showAlertModal={showPatientAlertModal}
                appointment={appointmentData}
              />
              <EmailModal
                isOpen={isOpenEmail}
                showEmailModal={showPatientEmailModal}
                appointment={appointmentData}
              />
            </div>
          </Col>
        </>
      ) : (
        <Col span={24}>
          <AppointmentDescriptionItem
            appointment={appointmentDataToDescriptionView}
          />
        </Col>
      )}
    </Row>
  )
}

AppointmentList.propTypes = propTypes
AppointmentList.defaultProps = defaultProps

export default AppointmentList
