import React, { useState, useEffect } from 'react'
import { Row, Col, Spin } from 'antd'
import styles from '../index.module.css'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { endsWith, startsWith } from 'ramda'
import dayjs from 'dayjs'

dayjs.locale('es')

const DescriptionConnectionState = ({ historiesData, historiesLoading }) => {
  const [dataConnection, setDataConnection] = useState([])

  const prepareInfo = historiesDataArr => {
    if (!historiesDataArr) return []

    const filteredHistories =
      historiesDataArr &&
      historiesDataArr.allHistories.filter(item => {
        return (
          endsWith('_DISCONNECTED', item.event) ||
          endsWith('_CONNECTED', item.event)
        )
      })

    if (filteredHistories.length < 1) return []

    const arrayDisconnected = [
      {
        name: dayjs(filteredHistories[0].createdAt)
          .subtract(1, 'minute')
          .format('DD/MM/YY HH:mm:ss'),
        paciente: 'desconectado',
        doctor: 'desconectado',
      },
    ]

    const connectedDisconnectedHistory = filteredHistories.map(history => {
      if (startsWith('PATIENT_', history.event))
        return {
          name: dayjs(history.createdAt).format('DD/MM/YY HH:mm:ss'),
          paciente: endsWith('_CONNECTED', history.event)
            ? 'conectado'
            : 'desconectado',
        }
      else
        return {
          name: dayjs(history.createdAt).format('DD/MM/YY HH:mm:ss'),
          doctor: endsWith('_CONNECTED', history.event)
            ? 'conectado'
            : 'desconectado',
        }
    })

    return arrayDisconnected.concat(connectedDisconnectedHistory)
  }

  useEffect(() => {
    const data = prepareInfo(historiesData)

    setDataConnection(data)

    return () => {
      setDataConnection([])
    }
  }, [historiesData])

  return (
    <Row className={styles.descriptionItemCard}>
      <Col span={24} className={styles.descriptionItemTitle}>
        Conexión detectada:
      </Col>
      <Col span={24} className={styles.descriptionItemBody}>
        <Row>
          <Col span={24}>
            <p className={styles.paragraphStatus}>
              {dataConnection && dataConnection.length > 0
                ? `Estado de conexión`
                : `Sin información disponible. `}
            </p>
            {historiesLoading ? (
              <Spin>Cargando información...</Spin>
            ) : (
              dataConnection &&
              dataConnection.length > 0 && (
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    data={dataConnection}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="category" orientation="left" width={80} />
                    <Tooltip />
                    <Legend />
                    <Line
                      connectNulls
                      type="stepAfter"
                      dataKey="paciente"
                      stroke="#8A96CF"
                      activeDot={{ r: 12 }}
                      strokeWidth={4}
                    />
                    <Line
                      connectNulls
                      type="stepAfter"
                      dataKey="doctor"
                      stroke="#C5D02C"
                      activeDot={{ r: 12 }}
                      strokeWidth={2}
                      strokeDasharray="20 20"
                    />
                  </LineChart>
                </ResponsiveContainer>
              )
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DescriptionConnectionState
