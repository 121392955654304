import { notification } from 'antd'

const duration = 5

export const openSuccessNotification = (message, description) => {
  notification.success({
    message,
    description,
    duration,
  })
}

export const openErrorNotification = (message, description) => {
  notification.error({
    message,
    description,
    duration,
  })
}

