import { gql } from 'apollo-boost'

const GET_DEPARTMENTS = gql`
  {
    allDepartments {
      _id
      name
    }
  }
`

const GET_PROFESSIONALS = gql`
  query getProfessionals($departments: [ID]) {
    allProfessionals(departments: $departments) {
      _id
      firstname
      lastname
      departments {
        _id
        name
      }
    }
  }
`
export { GET_DEPARTMENTS, GET_PROFESSIONALS }
