import { gql } from 'apollo-boost'

const GET_HISTORIES = gql`
  query($entity: HistoryEntityInput!) {
    allHistories(entity: $entity) {
      _id
      event
      entity {
        name
        _id
      }
      meta {
        value
        name
      }
      createdAt
      updatedAt
    }
  }
`

const GET_UPLOADS = gql`
  query($room: String!) {
    uploads(room: $room) {
      size
      key
    }
  }
`

const GET_UPLOAD_FETCH_URL = gql`
  query($key: String!) {
    uploadFetchUrl(key: $key)
  }
`

export { GET_HISTORIES, GET_UPLOADS, GET_UPLOAD_FETCH_URL }
