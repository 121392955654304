import { useMemo, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import fillListOfHours from 'App/Helpers/fillListOfHours'
import { GET_APPOINTMENTS } from 'App/Queries'
export default (n, selectedProfessionals, selectedDepartments, datePicker) => {
  const [
    filledStructuredAppointments,
    setFilledStructuredAppointments,
  ] = useState({})
  const [appointments, setAppointments] = useState([])
  const hoursList = useMemo(() => fillListOfHours(n), [n])
  const firstDate = new Date()
  firstDate.setHours(0, 0, 0, 0)
  const from = datePicker !== null ? datePicker[0] : firstDate.toISOString()
  const secondDate = new Date()
  secondDate.setHours(23, 59, 59, 999)
  const to = datePicker !== null ? datePicker[1] : secondDate.toISOString()

  const { data, loading } = useQuery(GET_APPOINTMENTS, {
    variables: { selectedProfessionals, selectedDepartments, from, to},
  })

  useEffect(() => {
    if (!data) return
    const structuredAppointments = hoursList.reduce((acc, time) => {
      acc[time] = {
        time,
        appointments: [],
      }
      return acc
    }, {})
    data.allAppointments.forEach(appointment => {
      const { hour, minute } = appointment
      const ensureTwoDigits = n => `${n < 10 ? `0${n}` : n}`
      const formattedTime = `${ensureTwoDigits(hour)}:${ensureTwoDigits(
        minute,
      )}`
      if (structuredAppointments[formattedTime])
        structuredAppointments[formattedTime].appointments.push(appointment)
    })
    setFilledStructuredAppointments(structuredAppointments)
  }, [hoursList, data])

  useEffect(() => {
    const appo = Object.values(filledStructuredAppointments).reduce(
      (acc, block) => {
        const newItems = []
        let minute = 0
        let fullTime = ''
        if (block.appointments.length === 0) fullTime = block.time.split(':')
        minute = fullTime[1]
        if (minute % 30 === 0) newItems.push({ time: block.time })
        else
          block.appointments.forEach(appointment =>
            newItems.push({ time: block.time, appointment }),
          )
        return [...acc, ...newItems]
      },
      [],
    )
    setAppointments(appo)
  }, [filledStructuredAppointments])

  return { loading, appointments }
}
