import { gql } from 'apollo-boost'

const GET_USER = gql`
  {
    session {
      _id
      firstname
      lastname
    }
  }
`
export { GET_USER }